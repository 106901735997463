<template>
   <div class="card cursor-pointer mb-0" ref="channel" @click="selectPermission" :class="selected == permission.id ? 'border-theme color-theme' : ''">
      <div class="body py-3">
         <div class="row">
            <div class="col align-self-center">
               <h6 class="limitador my-0">{{ permission.name == null ? 'Unknown' : permission.name }}</h6>
            </div>
            <div class="w-max-content" v-if="selected == permission.id"><i class="far fa-check font-19"></i></div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Permission',
   props: ['permission', 'selected'],
   methods: {
      selectPermission : function () {
         this.$emit('selectPermission', this.permission)
      }
   }
}

</script>

<style scoped>

.card:hover {
   transform: scale(1.0025) !important;
   transition: all 0.1s ease !important;
}

</style>