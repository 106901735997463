<template>
   <div class="row" v-if="settings.idPermission == null">
      <div class="col-12">
         <div class="container">
            <div class="card">
               <div class="bg-banner">
                  <h1>Ticket <span class="mx-4 d-none d-sm-inline">|</span> {{ $t("navBar.ticket.settings") }}</h1>
               </div>
               <div class="card-body">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="text-center mb-1">{{ $t("ticketSettings.permission.title") }}</h6>
                     <p class="text-center mb-0">{{ $t("ticketSettings.permission.desc") }}</p>
                     <hr>
                  </div>
                  <div class="d-sm-flex align-items-center w-max-content mx-auto px-1 mb-1">
                     <label class="mr-2 mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("ticketSettings.permission.perm") }}:</label>
                     <v-select title="Desktop" class="w250" :options="permissionsList" label="name" @input="saveSettings" />
                     <select title="Mobile" class="w250 form-control" @input="saveSettings">
                        <option v-for="(option, index) in permissionsList" :key="index" :value="option">{{ option.name }}</option>
                     </select>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row" v-else>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body d-flex p-10">
               <div class="col">
                  <ul class="nav nav-tabs2">
                     <li class="nav-item">
                        <a class="nav-link show active" data-toggle="tab" href="#one" @click="modalConfig.toggleMessage = false">
                           <i class="far fa-cog font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ticketSettings.buttons.settings") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#two" @click="modalConfig.toggleMessage = false">
                           <i class="far fa-comments-alt font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ticketSettings.buttons.messages") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#three" @click="modalConfig.toggleMessage = true">
                           <i class="far fa-robot font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ticketSettings.buttons.automation") }}</span>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="w-max-content">
                  <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(autoMessage)" v-if="modalConfig.toggleMessage">
                     <i class="fal fa-plus font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("ticketSettings.add") }} {{ $t("ticketSettings.message") }}</span>
                  </button>
                  <button class="btn btn-primary h-auto rounded-sm" @click="saveSettings" v-else>
                     <i class="fal fa-save font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("ticketSettings.buttons.save") }}</span>
                  </button>
               </div>
            </div>
         </div>

         <div class="tab-content mt-0">
            <div class="tab-pane show active" id="one">
               <div class="row">
                  <div class="col-md-6 col-lg-4 mb-2">
                     <div class="card mb-0">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("serverSettings.alterSettings.permission.title") }} </h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("serverSettings.alterSettings.permission.sub") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <permission v-for="(permission, index) in permissionsList" :key="index" :permission="permission" :selected="settings.idPermission" @selectPermission="selectPermission($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-6 col-lg-4 mb-2">
                     <div class="card mb-0">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-stream color-theme font-13 mr-1"></i> {{ $t("serverSettings.selectCategory.category") }} </h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("serverSettings.selectCategory.desc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <category v-for="(category, index) in categoriesList" :key="index" :category="category" :selected="settings.idCategory" @selectCategory="selectCategory($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 mb-2">
                     <div class="card mb-0" v-if="!modalConfig.toggleStatus">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-sync color-theme font-13 mr-1"></i> {{ $t("serverSettings.statusArray.title") }} </h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("serverSettings.statusArray.desc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <status v-for="(status, index) in settings.status" :key="index" :index="index" :status="status" @edit="editStatus($event)" @remove="removeStatus($event)" />
                                 </div>
                              </div>
                              <div class="col-12 px-1 mt-3 text-center" v-if="settings.status.length < 5">
                                 <button type="button" class="btn btn-primary px-3" @click="modalConfig.toggleStatus = true;"><i class="far fa-plus mr-1"></i> {{ $t("serverSettings.status.toggle") }}</button>
                              </div>
                           </div>   
                        </div>
                     </div>
                     <div class="card mb-0" v-else>
                        <div class="card-body">
                           <div class="col-12 px-1 mb-1">
                              <h6 class="text-center mb-1"><i class="far fa-sync color-theme font-13 mr-1"></i> {{ $t("serverSettings.status.add") }} </h6>
                              <p class="text-center mb-0 d-none d-xl-block">{{ $t("serverSettings.status.addDesc") }}</p>
                              <hr class="d-none d-xl-block">
                           </div>
                           <div class="col-12 px-1 mb-3">
                              <TextAreaDeck :model="status.prefix" height="100" :label="`<i class='far fa-hashtag color-theme font-13 mr-1'></i> ${$t('serverSettings.status.prefix')}`" @attValor="status.prefix = $event;" />
                           </div>
                           <div class="col-12 px-1 mb-3">
                              <label class="mb-1"><i class="far fa-ellipsis-h font-13 color-theme mr-1"></i> {{ $t("serverSettings.status.desc") }}</label>
                              <input type="text" class="form-control" v-model="status.name" maxlength="200" :disabled="!status.custom" />
                           </div>
                           <div class="col-12 px-1 text-right">
                              <button type="button" class="btn btn-outline-primary h-auto mr-2" @click="modalConfig.toggleStatus = false;"><i class="far fa-undo font-11 mr-1"></i> {{ $t("serverSettings.buttons.back") }}</button>
                              <button type="button" class="btn btn-primary h-auto" @click="atualizarStatus"><i class="far fa-check font-12 mr-1"></i> {{ $t("serverSettings.status.save") }}</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="two">
               <div class="row">
                  <div class="col-12">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <h6 class="limitador my-0 cursor-pointer" title="Edit" @click="editChannel">
                              <span>{{ $t("ticketSettings.selected") }}: <span class="ml-1" v-if="settings.nameChannel != null">#{{ settings.nameChannel }}</span>
                              <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                           </h6>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.channel != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ticketSettings.channelMessage") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.channel.enabled" @click="toggleMessage('channelTicket')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.channel.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.channel.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.send") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.channel)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.channel.enabled" :message="preview.channel" :dadosUsuario="dadosUsuario" />
                  </div>
            
                  <div class="col-md-6 col-xl-4" v-if="preview.end != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ticketSettings.end") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.end.enabled" @click="toggleMessage('endTicket')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.end.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.end.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.end)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.end.enabled" :message="preview.end" :dadosUsuario="dadosUsuario" />
                  </div>
               </div>
            </div>

            <div class="tab-pane" id="three">
               <div class="row">
                  <div class="col-md-6 col-xl-4" v-if="preview.start != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ticketSettings.start") }}<i class="far fa-hashtag font-13 ml-3"></i> {{ preview.start.id }}</h6>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.start)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.start.enabled" :message="preview.start" :dadosUsuario="dadosUsuario" />
                  </div>
                  <div class="col-md-6 col-xl-8">
                     <div class="cards-3">
                        <div class="card mb-0" v-for="(msg, index) in preview.messages" :key="index">
                           <div class="card-body p-0">
                              <div class="row mx-3 mt-3 align-items-center">
                                 <div class="col">
                                    <h6 class="limitador my-0">{{ $t("ticketSettings.message") }}<i class="far fa-hashtag font-13 ml-3"></i> {{ msg.id ? msg.id : '?' }}</h6>
                                 </div>
                                 <div class="w-max-content pl-1">
                                    <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(msg)"><i class="fal fa-pen font-11 mr-1"></i> {{ $t("ticketSettings.edit") }}</button>
                                 </div>
                                 <div class="w-max-content pl-1">
                                    <button class="btn btn-outline-primary h-auto rounded-sm" @click="removePreview(index)"><i class="fal fa-trash"></i></button>
                                 </div>
                                 <div class="col-12"><hr class="mb-0"></div>
                              </div>
   
                              <PreviewMessageDeck class="m-0 p-0 bg-transparent border-0" :message="msg" :dadosUsuario="dadosUsuario" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalChannel -->
      <div class="modal fade" id="modalChannel" tabindex="-1" aria-labelledby="modalChannelLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalChannelLabel">{{ $t("serverSettings.selectChannel.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-0">{{ $t("serverSettings.selectChannel.sub") }}</h6>
                                 <hr>
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="settings.idChannel" @selectChannel="saveChannel($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.selectChannel.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ autoMessage.id ? $t("ticketSettings.edit") : $t("ticketSettings.add") }} {{ $t("ticketSettings.message") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <div class="row">
                           <div class="col-lg-6 col-xxl-12">
                              <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                           </div>
                           <div class="col-lg-6 col-xxl-12" v-if="message.tipo == 'startTicket' || message.tipo == 'messageTicket'">
                              <div class="card">
                                 <div class="card-body p-3">
                                    <div class="row align-items-center">
                                       <div class="col-12"><h6 class="mb-3">➡️&nbsp;&nbsp;{{ $t("ticketSettings.interactiveButtons") }}</h6></div>
                                       <div class="col-12">
                                          <div class="row m-0" v-if="!modalConfig.toggleButton">
                                             <div class="col-12 px-1 mb-1">
                                                <div class="cards">
                                                   <interactiveButton v-for="(btn, index) in message.buttons" :key="index" :btn="btn" :index="index" :showEdit="true" @remove="removeButton($event)" />
                                                </div>
                                             </div>
                                             <div class="col-12 px-1 mt-3 text-center" v-if="message.buttons.length < 5">
                                                <button type="button" class="btn btn-primary h-auto" @click="modalConfig.toggleButton = true;"><i class="far fa-plus mr-1"></i> {{ $t("ticketSettings.add") }}</button>
                                             </div>
                                          </div>
                                          <div class="row m-0" v-else>
                                             <div class="col-12 px-1 mb-3">
                                                <TextAreaDeck :model="button.title" height="100" :label="`<i class='far fa-heading color-theme font-13 mr-1'></i> ${$t('ticketSettings.title')}`" @attValor="button.title = $event;" />
                                             </div>
                                             <div class="col-12 px-1 mb-3">
                                                <label class="mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("ticketSettings.permission.perm") }}:</label>
                                                <v-select title="Desktop" :options="permissionsList" label="name" v-model="button.idPermission" :reduce="p => p.id" />
                                                <select title="Mobile" class="form-control" v-model="button.idPermission">
                                                   <option v-for="(option, index) in permissionsList" :key="index" :value="option.id">{{ option.name }}</option>
                                                </select>
                                             </div>
                                             <div class="col-12 px-1 mb-3">
                                                <label class="mb-1"><i class="far fa-hashtag font-13 color-theme mr-1"></i> ID {{ $t("ticketSettings.destMessage") }}</label>
                                                <input type="text" class="form-control" v-model="button.idMessage" v-mask="'###############'" />
                                             </div>
                                             <div class="col-12 px-1 text-right">
                                                <button type="button" class="btn btn-outline-primary h-auto mr-2" @click="modalConfig.toggleButton = false;"><i class="far fa-undo font-11 mr-1"></i> {{ $t("ticketSettings.buttons.back") }}</button>
                                                <button type="button" class="btn btn-primary h-auto" @click="saveButton"><i class="far fa-check font-12 mr-1"></i> {{ $t("ticketSettings.buttons.save") }}</button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <button class="btn btn-primary btn-float" @click="saveSettings" v-if="showSave"><i class="fal fa-save"></i></button>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import status from '@/components/ticket/Status.vue'
import MessageDeck from '@/components/MessageDeck.vue'
import category from '@/components/ticket/Category.vue'
import channel from '@/components/whitelist/Channel.vue'
import TextAreaDeck from '@/components/TextAreaDeck.vue'
import permission from '@/components/ticket/Permission.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'
import interactiveButton from '@/components/ticket/InteractiveButton.vue'

export default {
   name: 'Ticket_settings',
   data: function () {
		return {
         message: null,
         showSave: false,
         settings: {'idServer': null, 'idChannel': null, 'nameChannel': null, 'idPermission': null, 'namePermission': null, 'idCategory': null, 'nameCategory': null, 'status': []},
         preview: {'channel': null, 'start': null, 'messages': [], 'end': null},
         status: {"id": null, "prefix": '', "name": '', "idServer": null, "custom": true},
         autoMessage: {
            'id': null, 'idServidor': null, 'tipo': 'messageTicket', 'enabled': true, 'message': 'Hey {user.mention}, tell us what you need!', 'usarImage': false, 'usarEmbed': false, 
            'image': "http://appdeck.online/default.png", 'imageTitle': "", 'imageSubtitle': "welcome to *{server}*", 'imageColorText': "hsl(188,0%,100%)", 'imageBorderSize': 3, 
            'imageFont': "Arial Black", 'imageFontSize': 12, 'fields': [], 'embedThumbnail': "http://appdeck.online/default.png", 'embedColor': "hsl(64,100%,50%)", 'embedAuthor': "Author name", 
            'embedAuthorImage': "http://appdeck.online/default.png", 'embedTitle': "Title text", 'embedMessage': "Message text", 'embedImage': "http://appdeck.online/default.png", 
            'embedFooter': "Footer text", 'embedFooterImage': "http://appdeck.online/default.png", 'customCommand': false, 'buttons': []
         },
         button: {'id': null, 'title': 'My button', 'idMessage': null, 'idPermission': null},
         modalConfig: {'toggleStatus': false, 'toggleMessage': false, 'toggleButton': false}
      }
   },
   computed: {
		...mapState({
         permissionsList: state => state.permissionsList,
         categoriesList: state => state.categoriesList,
         dadosUsuario: state => state.dadosUsuario,
         channelsList: state => state.channelsList,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
	},
   watch: {
      settings: {
         handler: function (newValue, oldValue) {
            if (newValue && newValue.idServer && oldValue && oldValue.idServer) {
               this.showSave = true
            }
         },
         deep: true
      },
   },
   components: {
      channel, permission, PreviewMessageDeck, MessageDeck, category, status, TextAreaDeck, interactiveButton
   },
   methods: {
      getSettings : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getsettingsticket',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.preview = response.data.preview;
            this.settings = response.data.settings;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editChannel : function () {
         $('#modalChannel').modal('show')
      },
      saveChannel : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.settings.idChannel = channel.id;
         this.settings.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterchannelticket',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            $('#modalChannel').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.saved')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      selectCategory : function (category) {
         this.settings.idCategory = category.id;
         this.settings.nameCategory = category.name;
      },
      selectPermission : function (permission) {
         this.settings.idPermission = permission.id;
         this.settings.namePermission = permission.name;
      },
      atualizarStatus : function () {
         if (this.status.name == null || String(this.status.name).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('serverSettings.status.invalid')
            })

            return
         }

         if (this.status.id) {
            let index = this.settings.status.findIndex(s => s.id == this.status.id)
            this.$set(this.settings.status, index, this.status)

         } else {
            this.settings.status.push(this.status)
         }

         this.modalConfig.toggleStatus = false;
         this.status = {"id": null, "prefix": '', "name": '', "idServer": null, "custom": true}
      },
      editStatus : function (status) {
         this.status = JSON.parse(JSON.stringify(status))
         this.modalConfig.toggleStatus = true;
      },
      removeStatus : function (index) {
         this.settings.status.splice(index, 1)
      },
      saveSettings : function (permission) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         
         if (permission.id) {
            this.settings.idPermission = permission.id;
            this.settings.namePermission = permission.name;
         }

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/savesettingsticket',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            let attServer = this.serverS
            attServer.idPermissionTicket = this.settings.idPermission
            this.$store.dispatch('updateServerS', attServer)

            this.showSave = false

            this.$toast.fire({
               icon: 'success',
               title: 'Saved settings!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editPreview : function (preview) {
         this.message = null;
         
         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
      attObjetoMessage: function(e) {
			this.message = e;
		},
      savePreview : function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getSettings()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      toggleMessage : function (type) {
         let value = null;

         setTimeout(() => {
            switch (type) {
               case "channelTicket":
                  value = this.preview.channel.enabled
               break;
               case "endTicket":
                  value = this.preview.end.enabled
               break;
            }

            this.$store.dispatch('toggleMessage', {type: type, value: value}).then(() => {
               this.$toast.fire({
                  icon: 'success',
                  title: this.$t("serverSettings.swal.message")
               })
            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')

                     this.$toast.fire({
                        icon: 'warning',
                        title: this.$t("serverSettings.swal.expired")
                     });
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Error: ' + error.response.status
                     });
                  }
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            })
         }, 100);
      },
      saveButton : function () {
         if (this.button.title == null || String(this.button.title).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('serverSettings.status.invalid')
            })

            return
         }

         this.message.buttons.push(JSON.parse(JSON.stringify(this.button)))
         this.button = {'id': null, 'title': 'My button', 'idMessage': null, 'idPermission': null}
         this.modalConfig.toggleButton = false
      },
      removeButton : function (index) {
         this.message.buttons.splice(index, 1)
      },
      removePreview : function (index) {
         Swal.fire({
            icon: 'warning',
            title: this.$t("ticketSettings.removeMessage"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest + 'servicos/removePreview',
                  params: {
                     id: this.preview.messages[index].id
                  }
               }).then(() => {
                  this.preview.messages.splice(index, 1)

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t("ticketSettings.removedMessage")
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired")
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Error: ' + error.response.status
                        });
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      },
      testMessage : function (type, idChannel) {
         this.$store.dispatch('sendMessage', {'type': type, 'idServer': this.serverS.id_s, 'idChannel': idChannel}).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.messageSent")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      }
   },
   mounted() {
      this.autoMessage.idServidor = this.serverS.id_s
      
      this.$store.dispatch('getPermissions')
      this.$store.dispatch('getCategories')
      this.$store.dispatch('getChannels')
      this.getSettings()
   }
}

</script>