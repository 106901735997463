<template>
   <div class="card mb-0">
      <div class="card-body py-12">
         <div class="row align-items-center">
            <div class="col">
               <h1 class="limitador font-15 mb-2">{{ btn.title }}</h1>
               <p class="limitador font-12 mb-0"><i class="far fa-long-arrow-right color-theme font-11 mr-2"></i>{{ $t("ticketSettings.message") }}<i class="far fa-hashtag font-10 ml-2"></i> {{ btn.idMessage ? btn.idMessage : $t("ticketSettings.unknown") }}</p>
               <p class="limitador font-12 mb-0"><i class="far fa-tag color-theme font-9 mr-2"></i>{{ permissionsList.find(permission => permission.id == btn.idPermission) ? permissionsList.find(permission => permission.id == btn.idPermission).name : $t("ticketSettings.unknown") }}</p>
            </div>
            <div class="w-max-content mb-1 ps-12" v-if="showEdit"><i class="far fa-trash text-danger cursor-pointer" @click="remove(index)"></i></div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'InteractiveButton',
   props: ['btn', 'index', 'showEdit'],
   computed: {
		...mapState({
         permissionsList: state => state.permissionsList
		}),
	},
   methods: {
      remove : function () {
         this.$emit('remove', this.index)
      }
   }
}

</script>