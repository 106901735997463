<template>
   <div class="card cursor-pointer mb-0" @click="selectCategory" :class="selected == category.id ? 'border-theme color-theme' : ''">
      <div class="body py-3">
         <div class="row">
            <div class="col align-self-center">
               <h6 class="limitador my-0">{{ category.name == null ? 'Unknown' : category.name }}</h6>
            </div>
            <div class="w-max-content" v-if="selected == category.id"><i class="far fa-check font-19"></i></div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Category',
   props: ['category', 'selected'],
   methods: {
      selectCategory : function () {
         this.$emit('selectCategory', this.category)
      }
   }
}

</script>

<style scoped>

.card:hover {
   transform: scale(1.0025) !important;
   transition: all 0.1s ease !important;
}

</style>